import React from 'react';
import {Route} from 'react-router-dom';
import {useBothAuthService} from '../provider/AuthProvider';
import {AUTH_TYPE_COGNITO} from '../properties/auth';
import {pushPathChange} from '../utils/googleAnalyticsUtil';

const ProtectedRoute = ({component, ...otherProps}) => {
    const {authService, oktaAuthService} = useBothAuthService();
    const service = otherProps.auth === AUTH_TYPE_COGNITO ? authService : oktaAuthService;
    const pathChangeTrackingText = otherProps.auth === AUTH_TYPE_COGNITO ? 'NEW_SIGNIN' : 'EXISTING_SIGNIN';
    const pathChangeTrackingTitle = otherProps.auth === AUTH_TYPE_COGNITO ? 'New_SignIn' : 'Existing_SignIn';

    const renderHandler = (Component) => (props) => {
        if (!!Component && service.isAuthenticated()) {
            return <Component {...props} />;
        } else {
            pushPathChange('pageViewLoad', pathChangeTrackingText, pathChangeTrackingTitle);
            service.signinRedirect();
            return <span/>;
        }
    };
    return <Route {...otherProps} render={renderHandler(component)} />;
};

export default ProtectedRoute;